/* eslint-disable  no-unused-vars */
import currenciesIcons from '@/helpers/currenciesIcons';
import EventBus from '@/helpers/event-bus';

export default {
  name: 'List',
  props: [
    'items',
    'type',
    'selectorType',
    'selectItem',
    'selectedItem',
    'bullets',
    'afterMerchantListText',
    'still',
    'multipleFilter',
    'removeWordFromList',
    'preselectAll',
    'selectAllFilter',
    'fixedTop',
    'spaceBetween',
  ],
  computed: {
    icons: () => currenciesIcons,
  },
  data: () => ({
    selectedMultipleFilters: {},
    returnMultipleFiltersArray: [],
    selectedAll: false,
  }),
  beforeMount() {
    (this as any).updateList();
  },
  mounted() {
    const self: any = this as any;

    EventBus.$on('clear-filters', () => {
      self.selectedMultipleFilters = {};
    });

    // EventBus.$on('clear-filter-by-value', (value: any) => {
    //   console.log(value);
    //   const filteredObject = Object.fromEntries(
    //     Object.entries(self.selectedMultipleFilters).filter(
    //       ([_, item]: [any, any]) =>
    //         item.value?.toLowerCase() !== value.toLowerCase() ||
    //         item.text?.toLowerCase() !== value.toLowerCase()
    //     )
    //   );

    //   console.log(filteredObject, value, self.selectedMultipleFilters);

    //   self.selectedMultipleFilters = filteredObject;
    // });

    if (self.preselectAll) {
      self.selectedAll = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('clear-filters');
    // EventBus.$off('clear-filter-by-value');
  },
  methods: {
    updateList() {
      const self: any = this as any;
      self.selectedMultipleFilters = {};

      if (self.selectedItem && self.multipleFilter) {
        for (const sItem of self.selectedItem) {
          const index = self.items.findIndex(
            (item: any) => item.text === sItem.text
          );

          if (index > -1) self.selectedMultipleFilters[index] = sItem;
        }
      }
    },
    handleChange(event: any, item: any, index: number) {
      const self: any = this as any;
      const selected = { ...self.selectedMultipleFilters };

      if (selected[index]) {
        delete selected[index];
      } else {
        selected[index] = item;
      }

      self.selectedMultipleFilters = selected;
      self.returnMultipleFiltersArray = Object.values(selected);
    },
    isMerchant(item: any) {
      return item.parentLabel;
    },
    setParentBulletClass(item: any) {
      const merchantName = item.parentLabel;
      const foundChildActive = (this as any).items.find(
        (i: any) => i.value === (this as any).selectedItem
      );

      if (
        merchantName &&
        foundChildActive &&
        foundChildActive.childLabel === merchantName
      ) {
        return 'active-parrent';
      }
    },
    setChildBulletClass(item: any) {
      const self = this as any;
      if (item.parentLabel && item.parentLabel === self.selectedItem) {
        return 'active-parent-only';
      }

      if (item.value === self.selectedItem) {
        return 'active-child';
      }
    },
  },
  watch: {
    selectedItem() {
      (this as any).updateList();
    },
    selectedAll(val: any) {
      const self: any = this as any;

      self.items.forEach((element: any, index: number) => {
        if (
          (val && !self.selectedMultipleFilters[index]) ||
          (!val && self.selectedMultipleFilters[index])
        )
          self.handleChange(null, element, index);
      });

      self.selectItem(self.returnMultipleFiltersArray);
    },
  },
};
