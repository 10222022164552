/* eslint-disable no-unused-vars */
import Vue from 'vue';
import debounceMixin from '@/plugins/debounceMixin';
import Activator from './activator/Activator.vue';
import List from './list/List.vue';

interface IComponentData {
  menu: boolean;
  filteredOptions: any | null;
  filteredPrompt: string;
  filteredPromptTimer: number | null;
  llItems: any[];
  llPage: number;
  llPerPage: number;
  llLoading: boolean;
  llItemsTotal: number;
  search: string | null;
  observer: IntersectionObserver | null;
}

interface IComponentComputed {
  selectedItem: any;
  selectedItemText: any;
  activatorData: {
    height: any;
    width: any;
    selectedItem: any;
    menu: any;
    icon: any;
    type: any;
    label: any;
    selectedItemText: any;
    hightlightActive: boolean;
    hideChoice: boolean;
  };
}

interface IComponentMehods {
  filterOptions(event: any): void;
  selectItem(item: any): void;
  loadItems(s?: string): Promise<any>;
  searchHandler(value: string): void;
  removeObserver(): void;
  setupObserver(): void;
}

interface IComponentProps {
  hideCount: boolean;
  fullWidth: boolean;
  items: any[];
  value: any;
  label: string;
  width: string;
  height: string;
  icon: boolean;
  mdiIcon: string;
  type: string;
  returnObject: boolean;
  rounded: boolean;
  disabled: boolean;
  hightlightActive: boolean;
  bullets: boolean;
  afterMerchantListText: string;
  hideChoice: boolean;
  contentHeight?: string;
  upperCase?: boolean;
  textTransformNone?: boolean;
  still?: boolean;
  contentClass?: string;
  activatorClass?: string;
  scrollable: boolean;
  multipleFilter: boolean;
  removeWordFromList?: string;
  selectAll?: boolean;
  selectAllFilter: boolean;
  contentWdith?: string;
  spaceBetween?: boolean;
  lazyLoad?: boolean;
  lazyLoadUpdateHandler?: (value: {
    offset: number;
    limit: number;
    search?: string;
  }) => Promise<any>;
}

export default Vue.extend<
  IComponentData,
  IComponentMehods,
  IComponentComputed,
  IComponentProps
>({
  name: 'CustomSelector',
  components: {
    Activator,
    List,
  },
  props: {
    hideCount: {
      type: Boolean,
      default: false,
    },
    afterMerchantListText: {
      type: String,
      default: '',
    },
    bullets: {
      type: Boolean,
      default: false,
    },
    hightlightActive: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: [] as any[],
    },
    value: {
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '40',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    mdiIcon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'first',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideChoice: {
      type: Boolean,
      default: false,
    },
    contentHeight: {
      type: String,
      default: null,
    },
    contentWdith: {
      type: String,
      default: null,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    textTransformNone: {
      type: Boolean,
      default: false,
    },
    still: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: null,
    },
    activatorClass: {
      type: String,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    multipleFilter: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    removeWordFromList: {
      type: String,
      default: null,
    },
    selectAllFilter: {
      type: Boolean,
      default: true,
    },
    spaceBetween: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    lazyLoadUpdateHandler: {
      type: Function,
      default: null,
    },
  },
  mixins: [debounceMixin],
  data: () => ({
    menu: false,
    filteredOptions: null,
    filteredPrompt: '',
    filteredPromptTimer: null,

    llItems: [] as any[],
    llPage: 0,
    llPerPage: 10,
    llLoading: false,
    llItemsTotal: 0,

    search: null,
    observer: null,
  }),
  updated() {
    if (this.menu) {
      document.addEventListener('keydown', this.filterOptions);
    } else {
      this.filteredOptions = null;
      document.removeEventListener('keydown', this.filterOptions);
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        if (typeof value === 'string' && value.toLowerCase() === 'default') {
          this.$emit('input', null);
        } else {
          this.$emit('input', value);
        }
      },
    },
    selectedItemText() {
      if (this.items?.length <= 0 || !this.selectedItem) return this.label;
      const foundedItem = this.items.find((item) => {
        if (
          (item.value &&
            item.value ===
              (this.returnObject
                ? this.selectedItem.value
                : this.selectedItem)) ||
          (item.text &&
            item.text ===
              (this.returnObject ? this.selectedItem.text : this.selectedItem))
        )
          return true;

        return false;
      });

      if (!foundedItem) return this.label;
      return foundedItem.text;
    },
    activatorData() {
      const {
        height,
        width,
        selectedItem,
        menu,
        icon,
        type,
        label,
        selectedItemText,
        hightlightActive,
        hideChoice,
        mdiIcon,
      } = this;

      return {
        height,
        width,
        selectedItem,
        menu,
        icon,
        type,
        label,
        selectedItemText,
        hightlightActive,
        hideChoice,
        mdiIcon,
      };
    },
  },
  methods: {
    searchHandler(value) {
      if (this.llLoading || !this.lazyLoadUpdateHandler) return;
      this.removeObserver();

      this.llLoading = true;
      this.llPage = 0;

      this.lazyLoadUpdateHandler({
        offset: this.llPage * this.llPerPage,
        limit: this.llPerPage,
        search: value,
      })
        .then((data: any) => {
          this.llItems = data.data;
          this.llItemsTotal = data.total;
        })
        .finally(() => {
          this.llLoading = false;
          this.setupObserver();
        });
    },
    loadItems() {
      if (this.llLoading || !this.lazyLoadUpdateHandler)
        return Promise.resolve();
      this.llLoading = true;

      return this.lazyLoadUpdateHandler({
        offset: this.llPage * this.llPerPage,
        limit: this.llPerPage,
        search: this.search || undefined,
      })
        .then((data: any) => {
          this.llItems = [...this.llItems, ...data.data];
          this.llItemsTotal = data.total;
        })
        .finally(() => {
          this.llLoading = false;
        });
    },
    setupObserver() {
      // if (this.state.addressBookTotal <= this.llPage * this.llPerPage) return;
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.llPage += 1;
            this.loadItems();
          }
        });
      }, options);

      this.$nextTick(() => {
        const sentinel = this.$refs.sentinel as Element;
        if (this.observer && sentinel) {
          this.observer.observe(sentinel);
        }
      });
    },
    removeObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
    filterOptions(event) {
      if (this.filteredPromptTimer)
        window.clearTimeout(this.filteredPromptTimer);
      const typedLetters = event.key;

      if (!this.isLetter(typedLetters)) {
        this.filteredOptions = null;
        return;
      }

      this.filteredPrompt += typedLetters;

      // Filter options that match the typed letters
      this.filteredOptions = this.items.filter((item: any) =>
        item.text.toLowerCase().startsWith(this.filteredPrompt)
      );

      if (!this.filteredOptions?.length) {
        this.filteredOptions = null;
      }

      this.filteredPromptTimer = window.setTimeout(() => {
        this.filteredPrompt = '';
      }, 1000);
    },
    selectItem(item) {
      if (this.returnObject) {
        this.selectedItem = item;
      } else {
        this.selectedItem = item.value || item.text || item;
      }

      if (!this.multipleFilter) this.menu = false;
    },
  },
  watch: {
    async menu(newVal) {
      if (this.lazyLoad && this.lazyLoadUpdateHandler) {
        this.search = null;
        if (newVal) {
          await this.loadItems();
          this.setupObserver();
        } else {
          this.llItems = [];
          this.llPage = 0;
          this.llItemsTotal = 0;
          this.removeObserver();
        }
      }
    },
  },
});
