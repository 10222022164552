import { IBankDetailsList } from '@/models/interfaces/bankDetails/IBankDetails';
import { IBankDetailsStore } from '@/models/interfaces/stores/IBankDetails';

export default {
  state: {
    bankDetailsList: [],
  },
  getters: {
    bankDetailsList: (state: IBankDetailsStore) => state.bankDetailsList,
  },
  mutations: {
    SET_BANK_DETAILS_DATA(state: IBankDetailsStore, data: IBankDetailsList[]) {
      state.bankDetailsList = data;
    },
    RESET(state: IBankDetailsStore) {
      // eslint-disable-next-line no-unused-vars
      state.bankDetailsList = [];
    },
  },
  actions: {
    setBankDetailsData(context: any, value: IBankDetailsList[]) {
      context.commit('SET_BANK_DETAILS_DATA', value);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
