/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { closeLoadNotify } from '@/helpers/loadNotificationHelper';

interface IComponentData {
  isVisible: boolean;
  item: {
    text: string;
  };
  isLoading: boolean;
  isLoaded: boolean;
  timeout: number;
}

interface IComponentComputed {
  init: void;
}

interface IComponentMehods {
  loaded(text: string | null): void;
  init(type: string, text: string): void;
  close(): void;
  reset(): void;
  closeNotify(): void;
}

interface IComponentProps {
  value: boolean;
}

export default Vue.extend<
  IComponentData,
  IComponentMehods,
  IComponentComputed,
  IComponentProps
>({
  name: 'LoadNotification',
  data: () => ({
    isVisible: false,
    item: {
      text: '',
    },
    timeout: 5000,
    isLoading: false,
    isLoaded: false,
  }),
  methods: {
    init(text: string) {
      this.isVisible = true;
      this.item = {
        text,
      };

      setTimeout(() => {
        this.isLoading = true;
      }, 100);
    },
    loaded(text: string | null = null) {
      this.isLoading = false;
      this.isLoaded = true;

      if (text) {
        this.item = {
          text,
        };
      }
    },
    closeNotify() {
      closeLoadNotify(this);
    },
    close() {
      this.isVisible = false;
      this.reset();
    },
    reset() {
      this.item = {
        text: '',
      };
      this.isLoading = false;
      this.isLoaded = false;
    },
  },
  created() {
    this.$root.$refs.$loadNotify = this;
  },
});
