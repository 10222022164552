/* eslint-disable vue/no-deprecated-destroyed-lifecycle */
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      timerId: 0,
    };
  },
  methods: {
    debounce(handler: any, seconds: number) {
      if (this.timerId) clearTimeout(this.timerId);

      this.timerId = setTimeout(handler, seconds) as any;
    },
  },
});
