import { IAddressBookStore } from '@/models/interfaces/stores/IAddressBookStore';
import { IAddressBookList } from '@/models/interfaces/addressBook/IAddressBook';

export default {
  state: {
    addressBookList: [],
    addressBookTotal: 0,
    addressBookInitialTotal: 0,
  },
  getters: {
    addressBookList: (state: IAddressBookStore) => state.addressBookList,
    addressBookTotal: (state: IAddressBookStore) => state.addressBookTotal,
    addressBookInitialTotal: (state: IAddressBookStore) =>
      state.addressBookInitialTotal,
  },
  mutations: {
    SET_ADDRESS_BOOK_INITIAL_TOTAL(state: IAddressBookStore, data: number) {
      state.addressBookInitialTotal = data;
    },
    SET_ADDRESS_BOOK_DATA(state: IAddressBookStore, data: IAddressBookList[]) {
      state.addressBookList = data;
    },
    SET_ADDRESS_BOOK_TOTAL(state: IAddressBookStore, data: number) {
      state.addressBookTotal = data;
    },
    RESET(state: IAddressBookStore) {
      // eslint-disable-next-line no-unused-vars
      state.addressBookList = [];
      state.addressBookTotal = 0;
    },
  },
  actions: {
    setAddressBookData(context: any, value: IAddressBookList[]) {
      context.commit('SET_ADDRESS_BOOK_DATA', value);
    },
    setAddressBookTotal(context: any, value: number) {
      context.commit('SET_ADDRESS_BOOK_TOTAL', value);
    },
    setAddressBookInitialTotal(context: any, value: number) {
      context.commit('SET_ADDRESS_BOOK_INITIAL_TOTAL', value);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
