import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/bank-details',
      name: 'BankDetails',
      component: () => import('@/pages/bank-details/BankDetails.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
