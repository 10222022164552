/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import uk from './locales/uk.json';
import ru from './locales/ru.json';
import hu from './locales/hu.json';
import de from './locales/de.json';
import cs from './locales/cs.json';
import sk from './locales/sk.json';
import fi from './locales/fi.json';

i18next.use(LanguageDetector).init({
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: { translation: en },
    uk: { translation: uk },
    ru: { translation: ru },
    hu: { translation: hu },
    de: { translation: de },
    cs: { translation: cs },
    sk: { translation: sk },
    fi: { translation: fi },
  },
});

export default i18next;
